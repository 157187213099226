
.modal-title {
    font-size: 45px; /* Thay đổi kích thước font theo nhu cầu */
    /* Thêm các thuộc tính CSS khác nếu cần */
}
.customer label{
    font-size: 16pt;
    margin: 0  15%;
    font-weight: bolder;
}
.photo img{
    width: 90%;
    margin: 5%;
}
.customer .infoCustomer{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
}
.history{
    padding-left: 15%;
}
.history button{
    border: none;
    border-radius: 50%;
    height: 25%;
    background-color: aliceblue;
}
.customer .infoCustomer strong
{
    font-size: 18pt;
}
.customer .infoCustomer p{
    font-size: 16pt;
    font-weight: 500;
}
.customer .photo{
    margin-top: 20%;
}
.buttonUpdate_Delete{
    position: absolute;
    right: 2%;
    color: white;
}
.btnUpdate{
    margin-right: 10px;
    border: none;
    font-weight: bolder;
}
.btnUpdateImage{
    margin-left: 80%;
}
.btnDelete{
    background-color: red;
    border: none;
    font-weight: bolder;
}

