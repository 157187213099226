.text_content{
    text-align: center; /* Căn giữa theo chiều ngang */
    width: 100%; /* Chiều rộng tối đa */
}
p {
    margin: 0; /* Loại bỏ khoảng cách trống giữa các dòng */
}
.image img{
    width: 10%;
}
.bottom{
    display: flex;
    margin-top: 10px;
}
.image h4{
    text-align: center;
}
.button{
    margin-left: 75%;
}
.btnUpdate{
    margin-right: 60%;
}

