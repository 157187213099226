.login_container {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  .general {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: bisque;
    background: url("./../../assets/image/bgLogin.jpg") no-repeat;
    background-color: black;
    background-size: 150%;
    background-position: center bottom;

    .wrapper {
      width: 80%;
      position: absolute;
      left: 10%;
      top: 25%;
      background: transparent;
      border: 2px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(9px);
      color: #fff;
      border-radius: 12px;
      padding: 30px 40px;
      margin-right: 35%;
    }
    .wrapper h1 {
      font-size: 36px;
      text-align: center;
    }
    .wrapper .input_box {
      position: relative;
      width: 100%;
      margin: 30px 0;
    }
    .input_box input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      outline: none;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      font-size: 16px;
      color: #fff;
      padding: 20px 45px 20px 20px;
    }
    .input_box input::placeholder {
      color: #fff;
    }
    .input_box i {
      position: absolute;
      right: 20px;
      top: 30%;
      transform: translate(-50%);
      font-size: 20px;
      color: #fff;
    }
    .wrapper .remember_forgot {
      display: flex;
      justify-content: space-between;
      font-size: 14.5px;
      margin: -15px 0 15px;
    }
    .remember_forgot label input {
      accent-color: #fff;
      margin-right: 3px;
    }
    .remember_forgot a {
      color: #fff;
      text-decoration: none;
    }
    .remember_forgot a:hover {
      text-decoration: underline;
    }
    .wrapper .btn {
      width: 100%;
      height: 45px;
      background: #fff;
      border: none;
      outline: none;
      border-radius: 40px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      font-size: 16px;
      color: #333;
      font-weight: 600;
    }
    .wrapper .register_link {
      font-size: 14.5px;
      text-align: center;
      margin: 20px 0 15px;
    }
    .register_link p a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
    }
    .register_link p a:hover {
      text-decoration: underline;
    }
  }
}
