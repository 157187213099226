.car {
    width: 100%;
    color: white;
    height: auto;
    min-height: 100vh;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.car .top {
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 5% auto;
    height: 8%;
    align-items: center;
}

.car .top .search_box {
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.car .top .search_box i {
    padding-left: 3%;
    color: white;
    font-size: 24pt;
    margin-right: 5px; /* Thêm margin để tạo khoảng cách giữa biểu tượng và input */
}

.car .top .search_box input {
    color: white;
    font-size: 14pt;
    border: none;
    background: transparent; /* Để input có màu trắng */
}

.car .top .search_box input::placeholder {
    color: white;
}
.car .top button {
    margin-left: 15%;
    color: white;
    font-weight: bolder;
    border-radius: 100%;
    background-color: aliceblue;
    height: 30px;
    border: none;
}
.car .top button i {
    color: black;
    background-color: aliceblue;
    font-weight: bolder;
    font-size: 12pt;
}

.car .itemsCar {
    width: 90%;
    margin: 5% auto;
    border-radius: 20px;
    color: white;
}

.car .itemCar {
    font-size: 11pt;
    background-color: black;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    padding-bottom: 5%;
}
.car .imageCar {
    width: 85%;
    padding-top: 5%;
    margin: 5% auto;
}
.car .imageCar img {
    width: 100%;
}
.car .car_customer {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 3%;
}
.car .infoCar {
    width: 50%;
}
.car .infoCustomer {
    width: 50%;
}
/* .carStatus{
        width: 15%;
        position: relative;
        right: 0;
    } */
.car .infoCustomer button {
    width: 50%;
    border-radius: 20px;
    font-size: 8pt;
    padding: 3px;
    margin: 0 auto;
}

.car .booked {
    background-color: red;
    color: white;
    border: red;
}

.car .inactive {
    background-color: gray;
    color: black;
    border: gray;
}

.car .active {
    background-color: green;
    color: white;
    border: green;
}
