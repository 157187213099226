header {
    width: 100%;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.top {
    height: 60px;
}

header .bar {
    color: aliceblue;
    position: relative;
    margin: auto 5%;
}

header .bar i {
    font-size: 24pt;
}

header .logo {
    width: 20%;
    /* margin: 2% 0; */
}

header .logo img {
    /* width: 100%; */
    height: 50px;
}
/* Header.module.css */
header .showMenu {
    position: absolute;
    top: 100%; /* Hiển thị menu ở dưới header */
    left: 0;
    width: 100%; /* Menu chiếm toàn bộ chiều rộng */
    z-index: 1000; /* Đảm bảo menu hiển thị trên các phần tử khác */
    display: none; /* Mặc định ẩn menu */
}

header .showMenu {
    display: block; /* Hiển thị menu khi showMenu là true */
}

header .dash_content table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
}

header .dash_content table th,
header .dash_content table td {
    border: 1px solid black;
    padding: 8px;
}

header .dash_content table th {
    background-color: #5b5858;
    text-align: center;
    color: #fff;
}

header .dash_content table th:first-child,
header .dash_content table td:first-child {
    text-align: center;
    width: 10%;
}

header .dash_content table td:nth-child(2) {
    text-align: left;
    width: 50%;
}

header .dash_content table td:nth-child(3) {
    text-align: right;
    width: 30%;
}

header .dash_content table th:nth-child(4),
header .dash_content table td:nth-child(4) {
    text-align: center;
    width: 10%;
}
header .icon_changepass {
    color: wheat;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 4px;
    cursor: pointer;
  }
  