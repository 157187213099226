.dashboard {
    width: 100%;
    color: white;
    height: auto;
    min-height: 100vh;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.dashboard .dash_content {
    width: 90%;
    margin: 5% auto;
    background-color: black;
    border-radius: 20px;
    color: white;
    overflow-x: auto;
}

.dashboard .options {
    font-size: 15pt;
    width: 60%;
    margin: 3% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashboard .options label {
    padding-left: 10px;
}

.dashboard .datePicker {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}

.dashboard .total {
    width: 90%;
    margin: 5% auto;
    justify-content: space-between;
    color: white;
    display: flex;
    flex-direction: row;
    font-size: 15pt;
}

.dashboard .total .revenue,
.dashboard .total .payout {
    width: 40%;
    margin: 0 5%;
    background-color: black;
    border-radius: 20px;
    text-align: center;
    flex-direction: column;
}

.dashboard .table_content {
    white-space: nowrap;
}
